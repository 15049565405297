import React, { useEffect } from 'react';
import './Logistics.css';
import analyticallogistics from '../../Assets/analytical_logistics.gif';
import logisticsdevices from '../../Assets/logistics_devices.png';
import logisticsservices from '../../Assets/logistics-services.gif';
import logisticsprocess from '../../Assets/logistics-process.gif';


const Logistics = () => {

      // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // logistics services page content
    <div className='logistics_content'>
        <h5>Logistics</h5>
        <div className='logistics_para_content'>
            <img className='img1' src={logisticsservices} alt="logistics-services" />
            <p><b>RFID Tags and Readers:</b> Used to track and manage inventory, ensuring accurate and efficient item retrieval.</p>
            <p><b>Automated Guided Vehicles (AGVs):</b> Transport goods within warehouses, reducing manual handling and speeding up operations.</p>
            <p><b>Transportation Management Systems (TMS):</b> Optimize route planning, manage shipments, and track deliveries in real-time.</p>
            <p><b>Warehouse Management Systems (WMS):</b> Control inventory levels, manage warehouse operations, and improve order accuracy.</p>
            <p><b>Real-Time Tracking:</b> IoT sensors on vehicles and shipments provide continuous location updates, enhancing visibility and security.</p>
            <p><b>Fleet Management:</b> IoT devices track vehicle performance and driver behavior, optimizing fleet operations and maintenance.</p>
        </div>
        {/* benefits content with image */}
        <div className='logistics_benefits'>
            <img className='img2' src={logisticsdevices} alt="logistics-devices" />
            <h5>Benefits of Logistics:</h5>
            <div className='benefits'>
                <p>Enhanced accuracy in inventory tracking and order fulfillment.</p>
                <p>Increased efficiency and speed in material handling and transportation.</p>
                <p>Improved route efficiency and reduced transportation costs through optimized planning.</p>
                <p>Enhanced visibility and coordination across the supply chain, leading to timely deliveries.</p>
                <p>Improved cargo safety and quality through condition monitoring and alerts for potential issues.</p>
            </div>
        </div>
        {/* applications content with image */}
        <div className='logistics_applications'>
            <h5>Applications of Logistics:</h5>
            <div className='applications'>
                <p>Track inventory precisely, enhancing visibility and reducing errors in supply chain management.</p>
                <p>Optimizes routes and manages shipments, reducing transportation costs and improving delivery efficiency.</p>
                <p>Monitor cargo conditions in transit, ensuring quality and compliance with environmental standards.</p>
                <p>Uses robotics and AGVs to streamline inventory management and accelerate order fulfillment.</p>
                <p>IoT devices monitor vehicle performance and location, improving route planning and reducing maintenance costs.</p>
            </div>
            <img className='img3' src={analyticallogistics} alt="analytical-logistics" />
        </div>
        {/* logistics down image */}
        <div className='logistics_down_image'>
            <img className='img4' src={logisticsprocess} alt="logistics-process" />
        </div>
    </div>
  )
}

export default Logistics
