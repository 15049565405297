import React, { useEffect } from 'react';
import './Agriculture.css';
import agricultureplanning from '../../Assets/agriculture-planning.jpeg';
import agriculturedevices from '../../Assets/agriculture-devices.png';
import agricultureservices from '../../Assets/agriculture-services.avif';
import plantdemo from '../../Assets/plant-demo.gif';

const Agriculture = () => {

      // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // Agriculture service page content
    <div className='main_content'>
        <h2>Agriculture</h2>
        {/* para content with image */}
        <div className='sub_content1'>
            <img className='img1' src={agricultureplanning} alt="agriculture-planning" />
            <p><b>Drones:</b> Used for aerial surveying, crop monitoring, and pesticide spraying, reducing labor and improving precision.</p>
            <p><b>Tractors with GPS:</b> Equipped with GPS technology for precision farming, optimizing planting, tilling, and harvesting processes.</p>
            <p><b>Farm Management Software:</b> Tracks crop performance, financials, and resource usage, improving planning and decision-making.</p>
            <p><b>Weather Forecasting Tools:</b> Provide real-time weather updates and predictions, helping farmers plan activities accordingly.</p>
            <p><b>Smart Irrigation Systems:</b> Automatically adjust watering schedules based on soil moisture data, optimizing water usage.</p>
            <p><b>Precision Farming:</b> Combines IoT devices, data analytics, and GPS to optimize planting, fertilization, and pest control.</p>
        </div>
        {/* benefits content with image */}
        <div className='sub_content2'>
            <h5>Benefits of Agriculture:</h5>
            <img className='img2' src={plantdemo} alt="plant-demo" />
            <div className='benefits'>
                <p>Improved yield and resource efficiency through precise planting and harvesting.</p>
                <p>Better soil health management and optimized irrigation, reducing water and fertilizer waste.</p>
                <p>Improved crop planning and financial management with farm management software.</p>
                <p>Enhanced productivity through accurate weather forecasting and timely agricultural activities.</p>
                <p>Reduced water consumption and improved crop health with smart irrigation systems.</p>
                <p>Enhanced animal welfare and productivity through real-time monitoring and health tracking.</p>   
                <p>Higher crop yields and lower input costs through data-driven precision farming techniques.</p>
            </div>
        </div>
        {/* applications content with image */}
         <div className='sub_content3'>
            <h5>Applications of Agriculture:</h5>
            <div className='applications'>
                <p>Monitor crops and soil health, reducing manual labor and improving precision agriculture.</p>
                <p>Better soil health management and optimized irrigation, reducing water and fertilizer waste.</p>
                <p>Automates watering schedules based on soil data, optimizing water use and crop yield.</p>
                <p>Tracks crop data and resources, aiding in better planning and decision-making.</p>
                <p>Monitor animal health and location, enhancing livestock management and productivity.</p>
                <p>Provide real-time forecasts, helping farmers plan activities and reduce weather-related risks.</p>   
            </div>
            <img className='img3' src={agricultureservices} alt="agriculture-services" />
        </div>
        {/* services down image on the page */}
        <div className='sub_content4'>
            <img className='img4' src={agriculturedevices} alt="agriculture-devices" />
        </div>
    </div> 
  )
}

export default Agriculture 
