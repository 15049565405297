import React, { useEffect, useState, useRef } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Chat = () => {
  const phoneNumber = '919030620999'; // Your WhatsApp phone number
  const [buttonBottom, setButtonBottom] = useState(30); // Initial button position
  const footerRef = useRef(null); // Create a ref for the footer

  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const footerRect = footerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Adjust button position when footer is near
        if (footerRect.top < windowHeight && footerRect.bottom > 0) {
          const overlapAmount = windowHeight - footerRect.top + 20; // Adjust this value if needed
          setButtonBottom(overlapAmount); // Dynamically set the bottom distance
        } else {
          setButtonBottom(30); // Default position if footer is not in view
        }
      }
    };

    // Listen for scroll events
    window.addEventListener('scroll', handleScroll);
    
    // Trigger scroll handler on component mount to set initial position
    handleScroll();

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <FloatingWhatsApp
        floatStyle={{
          bottom: `${buttonBottom}px`, // Dynamic bottom position based on scroll
          right: '30px',
        }}
        phoneNumber={phoneNumber}
        accountName="Embed View"
        avatar="https://cdn-icons-png.flaticon.com/512/149/149071.png"
        statusMessage="Typically replies within a few minutes"
        chatMessage="Hi there! How can we help you?"
        allowClickAway
        notification
        notificationSound
        placeholder="Type your message here..."
      />
      {/* Footer section */}
      {/*  */}
      <footer ref={footerRef}>
        <div style={{ padding: '20px', background: '#292930', color: '#fff', textAlign: 'center' }}>
          <p>@{new Date(2021, 0, 1).getFullYear()} Embed View. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Chat;
