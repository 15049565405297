import React, { useEffect } from 'react';
import './LearnMore.css';
// import { IoMdArrowRoundBack } from "react-icons/io";
// import { GrLinkNext } from "react-icons/gr";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { GrNext } from "react-icons/gr";
import softwaredeveloper from '../../Assets/developer_coding_learnmore_img.webp';
// import softwareprogramer from '../../Assets/software_learnmore_img.jpg';
import softwaresprogramer from '../../Assets/softwares_learnmore_img.jpg';
import websitelearnmore from '../../Assets/website_learnmore_img.jpg';
import hardware from '../../Assets/hardware_learnmore_img.jpg';
// import hardwaretesting from '../../Assets/hardware_testing_learnmore_img.jpg';
import hardwarestesting from '../../Assets/hardwares_testing_learnmore_img.jpg';



const LearnMore = () => {

    // Scroll to top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    // slider with images
    const slider = document.querySelector(".slider");

    function activate(e) {
      const items = document.querySelectorAll(".item");
      if (e.target.matches(".next")) {
        slider.append(items[0]);
      }
      if (e.target.matches(".prev")) {
        slider.prepend(items[items.length - 1]);
      }
    }

    document.addEventListener("click", activate, false);

    return () => {
      document.removeEventListener("click", activate, false);
    };
  }, []);

  return (
    <main>
      {/* images for sliders */}
      <div className="slider">
        {[
          { src: softwaredeveloper, alt: "End-to-End Solutions for Your Business Needs", title: "End-to-End Solutions for Your Business Needs", description: "At Embed-View, we specialize in crafting tailored solutions that integrate hardware and software to meet your business needs. Our services include sensor data collection, cloud-based storage, and visualization. With our expertise in end-to-end service provision, we ensure that all aspects of your project are taken care of." },
          { src: softwaresprogramer, alt: "Innovative Hardware and Software for Your Business Growth", title: "Innovative Hardware and Software for Your Business Growth", description: "We are committed to accelerating your business growth with our innovative hardware and software solutions. Our data science acceleration service provides you with the necessary tools to make data-driven decisions and stay ahead of the competition. With our state-of-the-art technology, you can transform your business into a modern and efficient powerhouse." },
          { src: websitelearnmore, alt: "Versatile Solutions for Your Business Challenges", title: "Versatile Solutions for Your Business Challenges", description: "No matter what your business challenges are, we have the versatility to provide solutions that work for you. Our applications are customizable to fit your specific needs and can be integrated seamlessly with your existing systems. We work closely with you to ensure that our solutions meet your unique requirements." },
          { src: hardware, alt: "Exceptional Customer Experience for Your Peace of Mind", title: "Exceptional Customer Experience for Your Peace of Mind", description: "At Embed-View, we pride ourselves on providing exceptional customer experience. We are committed to ensuring that our solutions meet your expectations and exceed them. Our dedicated team of experts is always available to answer any questions and provide support throughout the project lifecycle." },
          { src: hardwarestesting, alt: "Partner With Us for Seamless Integration", title: "Partner With Us for Seamless Integration", description:" We understand the importance of seamless integration with your ecosystem. That's why we work closely with you to ensure that our solutions integrate seamlessly with your existing systems and processes. Partner with us for a worry-free integration process." },
        ].map((item, index) => (
          <div className="item" key={index}>
            <img src={item.src} alt={item.alt} loading="eager" />
            <div className='content'>
              <h4 className='title'>{item.title}</h4>
              <p className='description'>{item.description}</p>
              {/* <button>Read More</button> */}
            </div>
          </div>
        ))}
      </div>
      <nav className='nav'>
        <MdOutlineArrowBackIos className='prev'/>
        <GrNext className='next'/>
      </nav>
    </main>
  );
};

export default LearnMore;
