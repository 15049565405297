import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { IoMdClose } from 'react-icons/io'; 
import { RxHamburgerMenu } from 'react-icons/rx'; 
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import './Navbar.css';
import logo from '../../Assets/embed_view_logo.png';


const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  // const [openDropdown, setOpenDropdown] = useState('');


  useEffect(() => {
    const header = document.querySelector('header');
    const height = header.clientHeight;

    const updateHeader = () => {
      setIsSticky(window.scrollY > height);
      setIsVisible(window.scrollY > height * 0.06);
    };

    updateHeader();
    window.addEventListener('scroll', updateHeader);

      // Close menu on scroll
      const handleScroll = () => {
        if (isMenuOpen) {
          setIsMenuOpen(false); // Close the menu when scrolling
        }
      };
  
      window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', updateHeader);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMenuOpen]);

  const handleMouseEnter = (menu) => {
    if (menu === 'products') setProductsOpen(true);
    if (menu === 'services') setServicesOpen(true);
    if (menu === 'more') setMoreOpen(true);
  };

  const handleMouseLeave = (menu) => {
    if (menu === 'products') setProductsOpen(false);
    if (menu === 'services') setServicesOpen(false);
    if (menu === 'more') setMoreOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const toggleDropdown = (menu) => {
  //   setOpenDropdown(openDropdown === menu ? '' : menu);
  // };

  return (
    <div className={`navbar ${isSticky ? 'is-sticky' : ''}`}>
      <Link to="/"><img src={logo} alt="Logo" className='logo' /></Link>

      <div className="hamburger-menu" onClick={toggleMenu}>
        {isMenuOpen ? <IoMdClose size={30} className='close'/> : <RxHamburgerMenu size={30} className='open'/>}
      </div>

      <ul className={isMenuOpen ? 'open' : ''}>
        <li><Link to="/">Home</Link></li>
        <li 
          onMouseEnter={() => handleMouseEnter('products')} 
          onMouseLeave={() => handleMouseLeave('products')}
        >
          <div className="dropdown-title">
                  <Link to="/solutions">Solutions</Link>
                  {productsOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
              </div>
          {productsOpen && (
            <div className="dropdown-menu">
              <ul>
                <li><Link to="/clinical_data">Clinical Data</Link></li>
                <li><Link to="/fault_detection">Fault Detection</Link></li>
                <li><Link to="/agri_sens">AgriSens</Link></li>
              </ul>
            </div>
          )}
        </li>
        <li 
          onMouseEnter={() => handleMouseEnter('services')} 
          onMouseLeave={() => handleMouseLeave('services')}
        >
        <div className="dropdown-title">
            <Link to="/domain">Domain</Link>
            {servicesOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
        </div>
          {servicesOpen && (
            <div className="services-menu">
              <div className='services-content'>
                  <div className='services-row'>
                    <header>Developments</header>
                    <ul className='services-list'>
                      <li><Link to="/software_development">Software</Link></li>
                      <li><Link to="/hardware_development">Hardware</Link></li>
                    </ul>  
                  </div>
                  <div className='services-row'>
                    <h5>Industry</h5>
                    <ul className='services-list'>
                      <li><Link to="/healthcare">Healthcare</Link></li>
                      <li><Link to="/industries">Industries</Link></li>
                      <li><Link to="/logistics">Logistics</Link></li>
                      <li><Link to="/agriculture">Agriculture</Link></li>
                    </ul>  
                  </div>
              </div>
            </div>
          )}
        </li>
        <li 
          onMouseEnter={() => handleMouseEnter('more')} 
          onMouseLeave={() => handleMouseLeave('more')}
        >
        <div className="dropdown-title">
            <Link>More</Link>
            {moreOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
        </div>
        {moreOpen && (
            <div className="dropdown-menu">
              <ul>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
              </ul>
            </div>
          )}
        </li>

        <li><Link to="/account">Account</Link></li>
      </ul>


      {isVisible && (
        <div className="sticky-header">
          <Link to="/"><img src={logo} alt="Logo" className='logo' /></Link>
      <ul className={isMenuOpen ? 'open' : ''}>
        <li><Link to="/">Home</Link></li>
        <li 
          onMouseEnter={() => handleMouseEnter('products')} 
          onMouseLeave={() => handleMouseLeave('products')}
        >
        <div className="dropdown-title">
            <Link to="/solutions">Solutions</Link>
            {productsOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
        </div>
        {productsOpen && (
            <div className="dropdown-menu">
              <ul>
                <li><Link to="/clinical_data">Clinical Data</Link></li>
                <li><Link to="/fault_detection">Fault Detection</Link></li>
                <li><Link to="/agri_sens">AgriSens</Link></li>
              </ul>
            </div>
          )}
        </li>
        <li 
          onMouseEnter={() => handleMouseEnter('services')} 
          onMouseLeave={() => handleMouseLeave('services')}
        >
          <div className="dropdown-title">
            <Link to="/domain">Domain</Link>
            {servicesOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
        </div>
          {servicesOpen && (
            <div className="services-menu">
              <div className='services-content'>
                  <div className='services-row'>
                    <header>Developments</header>
                    <ul className='services-list'>
                      <li><Link to="/software_development">Software</Link></li>
                      <li><Link to="/hardware_development">Hardware</Link></li>
                    </ul>  
                  </div>
                  <div className='services-row'>
                    <h5>Industry</h5>
                    <ul className='services-list'>
                      <li><Link to="/healthcare">Healthcare</Link></li>
                      <li><Link to="/industries">Industries</Link></li>
                      <li><Link to="/logistics">Logistics</Link></li>
                      <li><Link to="/agriculture">Agriculture</Link></li>
                    </ul>  
                  </div>
              </div>
            </div>
          )}
        </li>
        <li 
          onMouseEnter={() => handleMouseEnter('more')} 
          onMouseLeave={() => handleMouseLeave('more')}
        ><div className="dropdown-title">
        <Link>More</Link>
        {moreOpen ? <FaChevronUp className="icon" /> : <FaChevronDown className="icon" />}
    </div>
        {moreOpen && (
            <div className="dropdown-menu">
              <ul>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
              </ul>
            </div>
          )}
        </li>

        <li><Link to="/account">Account</Link></li>
      </ul>
      </div>
      )}
    </div>
  );
}

export default Navbar;
