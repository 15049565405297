import React, { useEffect } from 'react';
import './Healthcare.css';
import healthcareservice from '../../Assets/healthcare_services.gif'; 
import healthcaretreatment from '../../Assets/healthcare-treatment.jpg';
import healthcareprocess from '../../Assets/healthcare-process.webp';
import healthcaredevices from '../../Assets/healthcare_devices.avif';

const Healthcare = () => {

      // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // healthcare services content
    <div className='healthcare_content'>
        <h5>Healthcare</h5>
        {/* healthcare content with image */}
        <div className='healthcare_para_content'>
            <img className='img1' src={healthcareprocess} alt="healthcare-process" />
            <p><b>Medical Devices:</b> Equipment such as MRI machines, ECG monitors, and digital stethoscopes are essential for diagnostics and treatment.</p>
            <p><b>Wearables:</b> Devices like smartwatches and fitness trackers monitor vital signs, such as heart rate, sleep patterns, and oxygen levels, providing real-time health data.</p>
            <p><b>Electronic Health Records (EHRs):</b> Digital records that store patients' medical history, treatment plans, and test results, allowing easy access and sharing among healthcare providers.</p>
            <p><b>Telemedicine Platforms:</b> Software that enables remote consultations, diagnosis, and monitoring through video calls and messaging.</p>
            <p><b>Smart Sensors: </b>Implanted or wearable sensors that continuously monitor vital signs, such as blood glucose levels in diabetic patients.</p>
            <p><b>Connected Medical Devices:</b> Infusion pumps, heart rate monitors, and ventilators that automatically transmit data to healthcare providers.</p>
        </div>
        {/* healthcare benefits content with image */}
        <div className='healthcare_benefits'>
            <img className='img2' src={healthcareservice} alt="healthcare-services" />
            <h5>Benefits of Healthcare:</h5>
            <div className='benefits'>
                <p>Improved accuracy in diagnostics and treatments.</p>
                <p>Enhanced patient monitoring and early detection of health issues.</p>
                <p>Streamlined workflow and improved efficiency in medical practices.</p>
                <p>Enhanced patient care with personalized treatment plans and data analytics.</p>
                <p>Reduced hospital visits and readmissions through remote monitoring.</p>
                <p>Real-time monitoring and alerts for critical health events.</p>
                <p>Better management of chronic diseases with continuous data collection and analysis.</p>  
            </div>
        </div>
        {/* healthcare applications content with image */}
        <div className='healthcare_applications'>
            <h5>Applications of Healthcare:</h5>
            <div className='applications'>
                <p>Track vital signs and fitness data, enhancing chronic disease management and patient care.</p>
                <p>Enable remote consultations and monitoring, reducing hospital visits and improving healthcare accessibility.</p>
                <p>Assist surgeons in precise, minimally invasive procedures, reducing recovery times and complications.</p>
                <p>Automate resource management, patient tracking, and infrastructure control, increasing operational efficiency and safety.</p>
                <p>Analyze medical images and data for early disease detection, supporting accurate and timely treatments.</p> 
            </div>
            <img className='img3' src={healthcaredevices} alt="healthcare_devices" />   
        </div>
        {/* healthcare down image */}
        <div className='healthcare_down_image'>
            <img className='img4' src={healthcaretreatment} alt="healthcare-treatment" />
        </div>
    </div>
  )
}

export default Healthcare
