import React, { useState, useEffect } from 'react';
import './PCBDesign.css';


const PCBDesign = () => {

    // Scroll to top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const [responseMessage, setResponseMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [messageColor, setMessageColor] = useState('green'); // State for message color
  const [phone, setPhone] = useState(''); // State for phone number
  const [isLoading, setIsLoading] = useState(false); // New loading state

  // Auto-hide response message after a few seconds
  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        setResponseMessage('');
      }, 3000); // Hide message after 3000 milliseconds (3 seconds)

      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [responseMessage]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading state

    const form = event.target;
    const formData = new FormData(form);

  // Extract form data
  let fullName = formData.get('full_name');
  const email = formData.get('email');
  // const phone = formData.get('phone');
  const coverLetter = formData.get('cover_letter');
  // const resume = formData.get('resume');

  // Clear previous error messages
  setResponseMessage('');
  setFormErrors({});

      // Validate the fields
    const errors = {};

    if (!validateFullName(fullName)) {
      errors.fullName = 'Full name must start with a capital letter and contain only letters and spaces.';
    }

    if (!validateEmail(email)) {
      errors.email = 'Invalid email format."@gmail.com" or "@yahoo.com".';
    }

    if (!validatePhone(phone)) {
      errors.phone = 'Must be an Indian number (10 digits starting with 6, 7, 8, or 9).';
    }

    if (!validateCoverLetter(coverLetter)) {
      errors.coverLetter = 'Cover letter must be at least 20 characters long.';
    }

        // If there are any errors, stop form submission and display errors
  if (Object.keys(errors).length > 0) {
  setFormErrors(errors);
  // setResponseMessage('Please fix the errors above.'); // Optional message for validation errors
  setMessageColor('red'); // Set color to red for errors
  setIsLoading(false); // Reset loading state
  return;
  }

    try {
      const response = await fetch('http://127.0.0.1:8000/api/pcb_design/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'The application has been successfully submitted.');
        setMessageColor('green');
        // Reset the phone number after successful submission
        setPhone(''); // Clear the phone input
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
            // Check for specific error message regarding existing email
            if (response.status === 409) { // Assuming 409 for email conflict
              setResponseMessage('Error: Email already exists.');
          } 
          else {
              setResponseMessage('Error: ' + JSON.stringify(errorData));
          }
          setMessageColor('red');
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
      setMessageColor('red');
    } finally {
      setIsLoading(false); // Always reset loading state
    } 
  };

  const validateFullName = (name) => {
    // Check if the full name starts with a capital letter and only contains letters and spaces
    const fullNameRegex = /^[A-Z][a-z]*(?:\s[A-Z][a-z]*)*$/;
    return fullNameRegex.test(name.trim());
  };
  const validateEmail = (email) => {
    const allowedDomains = ['gmail.com', 'yahoo.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailRegex.test(email)) {
      return false; // Invalid email format
    }
  
    // Extract the domain from the email and check if it's allowed
    const emailDomain = email.split('@')[1];
    return allowedDomains.includes(emailDomain);
  };
  

  const validatePhone = (phone) => {
    // Check if the phone number is a valid Indian phone number (10 digits)
    const phoneRegex = /^(?:[6789]\d{9})$/; // Starts with 7, 8, or 9 and followed by 9 digits
    return phoneRegex.test(phone);
  };

  const validateCoverLetter = (coverLetter) => {
    return coverLetter.trim().length >= 20;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only digits
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  return (
    // PCB page
<div className="pcb-description-container">
      {/* PCB description */}
      <div className="pcb-details">
        <h2>PCB Design and Engineer</h2>
        {/* PCB responsibilities description */}
        <h3>Responsibilities</h3>
        <div className='pcb-description'>
          <p>Design and develop PCB layouts and schematics using industry-standard design software.</p>
          <p>Select and source appropriate components for PCB designs.</p>
          <p>Analyze signal integrity and power integrity to ensure reliable performance.</p>
          <p>Optimize designs for manufacturability and testability, including DFM (Design for Manufacturability), DRC (Design Rule Check) and DFT (Design for Test) considerations.</p>
          <p>Collaborate with cross-functional teams, including hardware, firmware, and mechanical engineers, to integrate PCBs into electronic devices.</p>
          <p>Conduct design reviews and provide feedback to ensure compliance with project specifications and industry standards.</p>
          <p>Document design processes, specifications, and test plans.</p>
          <p>Stay informed about emerging PCB technologies, industry standards, and best practices to continually improve design efficiency and effectiveness.</p>
        </div>
        <h3>Required Education Skills</h3>
        {/* pcb education skills description */}
        <div className='pcb-description'>
          <p>Basic understanding of PCB design principles and electronic components.</p>
          <p>Footprint and symbol creation for components in PCB design.</p>
          <p>Familiarity with PCB design software such as Altium Designer, Eagle, or KiCad.</p>
          <p>Knowledge of signal integrity and power integrity principles.</p>
          <p>Understanding of manufacturing processes and design constraints for PCBs.</p>
          <p>Strong analytical and problem-solving skills.</p>
          <p>Ability to work independently and as part of a multidisciplinary team.</p>
          <p>Knowledge of standards and regulations related to PCB design and electronics, such as IPC standards.</p>
          <p>Experience with version control systems, such as Git, is a plus.</p>
        </div>
        {/* pcb additional description */}
        <div className='pcb-description-info'>
            <p><b>Job Category:</b>PCB Design and Engineer</p>
            <p><b>Job Type:</b>Full Time</p>
            <p><b>Job Location:</b>Moosarambagh</p>
        </div>
      </div>
      {/* pcb apply form */}
      <div className="pcb-apply-form">
        {/* pcb apply form heading */}
        <h3>Apply for this position</h3>
        {/* pcb apply form box */}
        <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name"/>
          {formErrors.fullName && <p className="error-message">{formErrors.fullName}</p>}
          
          <label>Email *</label>
          <input type="email" name="email"/>
          {formErrors.email && <p className="error-message">{formErrors.email}</p>}
          
          <label>Phone *</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px', color: '#000' }}>+91</span>
            <input
              type="tel"
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              maxLength={10}
              placeholder="Enter phone number"
              style={{ flex: 1,  border: '1px solid #ccc', borderRadius: '4px',marginTop: '15px' }} // Make input take remaining space
            />
          </div>
          {formErrors.phone && <p className="error-message">{formErrors.phone}</p>}
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4"></textarea>
          {formErrors.coverLetter && <p className="error-message">{formErrors.coverLetter}</p>}

          <label htmlFor="resume">Resume *</label>
          <input type="file" id="resume" name="resume"/>
          {formErrors.resume && <p className="error-message">{formErrors.resume}</p>}
          
          <button type="submit" disabled={isLoading}>Submit</button>
        </form>

         {/* Response message */}
         {isLoading ? (
          <div className="loading-message">
            <p>Please wait...</p>
          </div>
        ) : (
          responseMessage && (
            <div className="response-message" style={{ color: messageColor }}>
              <p>{responseMessage}</p>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default PCBDesign
