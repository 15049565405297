import React, { useEffect } from 'react';
import './AgriSens.css';
import endtoendsolutions from '../../Assets/END_TO_END_Solutions.png';
import dashboardagrisens from '../../Assets/dashboard_agrisen.jpg';
import agresensdashboard from '../../Assets/agrisens_dashboard2.jpg'; 
import analyticaldashboard from '../../Assets/analytical_dashboard.png';
import industrialmultisensor from '../../Assets/industrial_multi_sensor.png';

const AgriSens = () => {
    // Scroll to top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    // AgriSens page content
    <div className='agrisens-container'>
      {/* AgriSens content */}
        <div className='agrisens-content slide-right'>
            <h4>AgriSens</h4>
            <img src={endtoendsolutions} alt="end-to-end-solutions" />
            {/* AgriSens content text */}
            <div className='agrisens-text fade-in'>
                <p>
                  <b>Embed-View</b> specialized in delivering cutting-edge for enterprise use-case in the fields of Industrial IoT 4.0/5.0, 
                  smart Agriculture using IoT, Green Energy, Digital Healthcare solutions tailored for enterprise applications. <br /><br />
                  To effectively address challenges in enterprise use-cases, it is essential to have a comprehensive understanding of the 
                  entire ecosystem involved. <br /><br />
                  We specialize in rapid prototype development, with a turnaround time of 72 to 96 hours, to showcase new device or 
                  applications integrations efficiently.
                </p>
                <img src={dashboardagrisens} alt="dashboard-agrisens" />
            </div>
            {/* AgriSens content text2 */}
            <div className='agrisens-text2 slide-left'>
              <p>
                  Digital transformation is a critical initiative for businesses looking to stay acompetitive and innovative in today's fast=paced, 
                  technology-driven world. To create value from data and successfully embark on a digital transformation journey, enterprises should 
                  consider a comprehensive and well-planned approach.
              </p>
              <img src={agresensdashboard} alt="agrisens-dashboard" />
              {/* AgriSens content text2 sub-text */}
              <div className='sub-agrisens-text fade-in'>
                <p>Data Strategy and Governance.</p>
                <p>Data Integration and Management.</p>
                <p>Cloud Computing.</p>
                <p>IoT and Edge Computing.</p>
                <p>Security.</p>
              </div>
            </div>
            {/* AgriSens content text3 */}
            <div className='agrisens-text3 fade-in'>
              <p>
                An Embed-View IoT Gateway serves as a translator intermediary between IoT devices and central cloud services. It converts diverse device-specific 
                data formats into standardized protocols, aggregates data, performs preprocessing, enhances security, and can facilitate real-time processing. IoT 
                gateways streamline data flow in IoT ecosystems.
              </p> 
              <p>
                Device Communication Protocols:WiFi, LoRaWAN, CANBus, Ethernet Modbus, ZigBee, I2C, SPI and 4G/5G-LTE.
              </p>  
              <p>
                Device Data Transport Protocols:MQTT, CoAP, LwM2M and HTTP.
              </p>
              <p>
                Embed-View AIoT gateway enables Edge computing, Analytics using Spark and many AI use cases handled using MLib/TensorFlow.
              </p>
              <img src={analyticaldashboard} alt="analytical-dashboard" />
            </div>
            {/* AgriSens content text4 */}
            <div className='agrisens-text4 slide-right'>
              <img src={industrialmultisensor} alt="industrial-multisensor" />
              <p>The ED401 stands out as our most versatile data logger device, boasting zero-touch configuration, multi-sensor support, compatibility with multiple 
                protocols, and a portable design, making it a pivotal efficiency-enhancing device for a wide range of applications, including IIoT 4.0/5.0, Building 
                Management Systems (BMS), and Green Energy use-cases.</p> <br /><br />
              <div className='sub-agrisens-text4 fade-in'>
                <p>Sensor agnostic: connect to most off-the-shelf sensors to the industry standard interfaces and protocols.</p>
                <p>Communication and Monitoring: JSON-RPC, MQTT (broker, publisher, subscriber), WEB UI, CLI.</p>
                <p>Run all interfaces simultaneously.</p>
                <p>Connectivity: 4G LTE or LoRaWAN, WiFi and BLE 4.2.</p>
                <p>Embedded SIM.</p>
                <p>Configuration/Control Over-The-Air (COTA).</p>
                <p>Encrypted Firmware Upgrades Over-The-Air (FOTA).</p>
                <p>Secure firmware management and downloads.</p>
                <p>Battery powered.</p>
                <p>Edge processing for on-demand alarming.</p>
                <p>RFID reader.</p>
                <p>Extended power supply as per sensors requirements.</p>
                <p>M2M communication.</p>
                <p>Thermocouple data logger.</p>
                <p>GPS location tracker.</p>
                <p>These sensors can be embedded in everything from industrial machinery and vehicles to medical devices and environmental monitoring stations.</p>
                <p>Data collection may happen in real-time or at regular intervals depending on the application.</p>
              </div>  
            </div>
        </div>
    </div>
  )
}

export default AgriSens
