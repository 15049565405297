import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    // Terms and Conditions page
    <div className="terms-and-conditions">
        <h1>Terms and Conditions of Use:</h1>
        {/* Terms and Conditions text */}
        <div className="terms-and-conditions-text">
            <p>
            This Terms and Conditions of Use Agreement (the "Agreement") governs your use of the collection of Web pages (the “Website) and other proprietary digital content (the EMBED-VIEW SOLUTIONS PVT LTD available through the EMBED-VIEW SOLUTIONS PVT LTD) (EMBED-VIEW SOLUTIONS PVT LTD. URLs for those sites include www.embed-view.com).
            </p>
            <p>
            By using this site, you agree to accept and be bound by the Terms and Conditions. EMBED-VIEW SOLUTIONS PVT LTD & the right, at its sole discretion, to modify these Terms and Conditions, at any time, without prior notification and your continued use of this site constitutes your acceptance of any and all 
            modifications. We encourage all Users to frequently review these  
            </p>
        </div>
        {/* Terms and Conditions text2 */}
        <div className="terms-and-conditions-text2">
            <h1>Terms and Conditions:</h1>
            <p>
            Access and Passwords to EMBED-VIEW SOLUTIONS PVT LTD so that they can learn more about and receive access to EMBED-VIEW SOLUTIONS PVT LTD &, purchase EMBED-VIEW SOLUTIONS PVT LTD products, register for EMBED-VIEW SOLUTIONS PVT LTD events/programs, and interact with other EMBED-VIEW SOLUTIONS PVT LTD.
            </p>
            <p>
            EMBED-VIEW SOLUTIONS PVT LTD may provide you with a password to access certain resources, provided that you complete any required application process and provide accurate information in your membership information. You may use your password only to access the website in ways consistent with this Agreement — no other 
            access to or use of the website, EMBED-VIEW SOLUTIONS PVT LTD, or EMBED-VIEW SOLUTIONS PVT LTD solutions is authorized. Further, you agree not to re-circulate or otherwise distribute your password to other people or organizations or to copy offsite any part of EMBED-VIEW SOLUTIONS PVT LTD resources without written permission. 
            Please report any unauthorized use of your password promptly to info@embed-view.com. You acknowledge that you have read and understood EMBED-VIEW SOLUTIONS PVT LTD Privacy Policy and agree that EMBED-VIEW SOLUTIONS PVT LTD may collect, use, and distribute information pursuant to that policy. If you post comments or opportunities 
            to EMBED-VIEW SOLUTIONS PVT LTD through the website, you grant EMBED-VIEW SOLUTIONS PVT LTD a nonexclusive, royalty-free right to use that content. 
            </p>
        </div>
        {/* Terms and Conditions text3 */}
        <div className="terms-and-conditions-text3">
            <h1>Use of Site and Site Content:</h1>
            <p>
            The content displayed on this site, including but not limited to the design, images, illustrations, graphics, photography, text and audio and video clips, if applicable, are owned, licensed to, or used with permission of third parties by EMBED-VIEW SOLUTIONS PVT LTD unless otherwise noted. Such content is subject to 
            copyright, trademark, trade dress and other intellectual property rights owned by EMBED-VIEW SOLUTIONS PVT LTD, licensed to EMBED-VIEW SOLUTIONS PVT LTD or authorized for use by EMBED-VIEW SOLUTIONS PVT LTD on this site. 
            </p>
            <p>
            The content of this site is intended for personal, non-commercial use only. No authorization is given to reproduce, modify, publish, distribute, transmit, download or create derivative works from any content on this site except as specifically permitted in this Agreement, without written permission from EMBED-VIEW SOLUTIONS PVT LTD.
            </p>
            <p>
            You agree to abide by all applicable laws and regulations, including intellectual property laws, in connection with your use of the EMBED-VIEW SOLUTIONS PVT LTD. In particular, you certify that your use of any part of the EMBED-VIEW SOLUTIONS PVT LTD will be in compliance with US intellectual property laws and these Terms and 
            Conditions of Use. In using the EMBED-VIEW SOLUTIONS PVT LTD, Resources, and/or solutions, you further agree (a) not to violate anyone's rights of privacy, (b) not to act in any way that might give rise to civil or criminal liability, (c) not to use or attempt to use another person's password without their explicit consent, (d) 
            not to collect or store personal data about anyone, (e) not to interfere with the work of other Users or EMBED-VIEW SOLUTIONS PVT LTD staff, servers, or resources, (f) not to transmit or facilitate the transmission of unsolicited email ("spam"), (g) not to harass, threaten, or otherwise annoy anyone, and (h) not to act in any 
            way that might be harmful to minors, including, without limitation, transmitting or facilitating the transmission of child pornography, which is prohibited by federal law and may be reported to the authorities should it be discovered by EMBED-VIEW SOLUTIONS PVT LTD. 
            </p>
        </div>
        {/* Terms and Conditions text4 */}
        <div className="terms-and-conditions-text4">
            <h1>Authorizations and Credits:</h1>
            <p>
            You agree that we may contact you from time to time with surveys or other questions regarding your opinions about and uses of EMBED-VIEW SOLUTIONS PVT LTD, as well as with information we believe may be of interest to you. In addition, we request that, according to standard academic practice, if you use the 
            EMBED-VIEW SOLUTIONS PVT LTD or the EMBED-VIEW SOLUTIONS PVT LTD Website for any research that results in an article, a book, or other publication, you list the EMBED-VIEW SOLUTIONS PVT LTD resource or website as a resource in your bibliography.
            </p>
        </div>
        {/* Terms and Conditions text5 */}
        <div className="terms-and-conditions-text5">
            <h1>Termination:</h1>
            <p>
            EMBED-VIEW SOLUTIONS PVT LTD may immediately terminate this Terms and Conditions of Use Agreement upon written notice (including via email) to you if it determines, at its sole discretion, that any these Terms and Conditions of Use have been violated. Upon termination, you agree that EMBED-VIEW SOLUTIONS PVT LTD may immediately 
            deactivate any password it has issued to you and bar you from accessing the EMBED-VIEW SOLUTIONS PVT LTD Resources or the Website.
            </p>
        </div>
        {/* Terms and Conditions text6 */}
        <div className="terms-and-conditions-text6">
            <h1>Disclaimers:</h1>
            <p>
            EMBED-VIEW SOLUTIONS PVT LTD does not guarantee or warrant that the content available in the EMBED-VIEW SOLUTIONS PVT LTD resources/website is accurate, complete, non-infringing, or legally accessible in your jurisdiction, and you agree that you are solely responsible for abiding by all laws and regulations that may be 
            applicable to the viewing of the content. In addition, the EMBED-VIEW SOLUTIONS PVT LTD are provided to you on an as-is and as-available basis. You agree that your use of the website and the resources is at your sole risk. You understand and agree that EMBED-VIEW SOLUTIONS PVT LTD makes no warranty or representation 
            regarding the accuracy, currency, completeness, reliability, or usefulness of the content in the resources, that the website or the resources will meet your requirements, that access to the resources will be uninterrupted, timely, secure, or error free, or that defects, if any, will be corrected. We make no warranty 
            of any kind, either express or implied.
            </p>
        </div>
    </div>
  )
}

export default TermsAndConditions
