import React, { useEffect } from 'react';
import ServiceCard from './ServicesCard'; // Import from the same directory
import './Services.css';

// Import images from the assets folder
import img1 from '../../Assets/software.jpg';
import img2 from '../../Assets/hardware.jpg';
// import img3 from '../../Assets/website-development.jpg';
import img4 from '../../Assets/healthcare.avif';
import img5 from '../../Assets/industries.webp';
import img6 from '../../Assets/logistics.avif';
import img7 from '../../Assets/agriculture.avif';

const Services = () => {
    // Scroll to top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  // Create a card for each service
  return (
    <div>
      <h1 style={{ textAlign: 'center' , marginLeft: '12%'}}>Developments</h1>
      <ServiceCard
        img={img1}
        title="Software"
        subtitle="Learn More"
        link="/software_development" // Pass the link prop
      />
      <ServiceCard
        img={img2}
        title="Hardware"
        subtitle="Learn More"
        link="/hardware_development" // Pass the link prop
      />
      {/* <ServiceCard
        img={img3}
        title="Website Development"
        subtitle="Learn More"
        link="/website_development" // Pass the link prop
      /> */}
      <h2 style={{ textAlign: 'center' , marginLeft: '12%'}}>Industry</h2>
      <ServiceCard
        img={img4}
        title="Healthcare"
        subtitle="Learn More"
        link="/healthcare" // Pass the link prop
      />
      <ServiceCard
        img={img5}
        title="Industries"
        subtitle="Learn More"
        link="/industries" // Pass the link prop
      />
      <ServiceCard
        img={img6}
        title="Logistics"
        subtitle="Learn More"
        link="/logistics" // Pass the link prop
      />
      <ServiceCard
        img={img7}
        title="Agriculture"
        subtitle="Learn More"
        link="/agriculture" // Pass the link prop
      />
    </div>
  );
};

export default Services;
