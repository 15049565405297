import React, { useEffect } from 'react';
import './SoftwareDevelopment.css';
import softwareprogrammer from '../../Assets/software-programmer.webp';
import softwareservice from '../../Assets/software-services.gif';
import softwareworking from '../../Assets/software-working.gif';
import softwaredevelopment   from '../../Assets/software-development.gif';
import softwareemployees from '../../Assets/software-employees.gif';


const SoftwareDevelopment = () => {

      // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // Software Development service content
    <div className='software_content'>
        <h5>Software</h5>
        {/* para content with image */}
        <div className='software_para_content'>
        <img className='img1' src={softwareprogrammer} alt="software_programmer" />
            <p>
            Software development is the process of designing, writing, testing, and maintaining the source code and other components of a software application.
            </p>
            <p><b>Software Types:</b> Includes system software, application software, web applications, mobile applications, and embedded software.</p>
            <p><b>Tools:</b> Integrated Development Environments (IDEs) like Visual Studio Code, PyCharm, Eclipse, and Android Studio; version control systems like Git; and build tools like Maven and Gradle.</p>
            <p><b>DevOps:</b> A set of practices that combines software development (Dev) and IT operations (Ops) to shorten the development lifecycle.</p>
            <p><b>Security Practices:</b> Encryption, secure coding practices, penetration testing, and implementing authentication and authorization mechanisms.</p>
            <p><b>Cloud Platforms:</b> AWS, Azure, and Google Cloud Platform (GCP) offer services for hosting applications, databases, storage, and AI/ML models.</p>
            <p><b>Quantum Computing:</b> Emerging technology with potential implications for cryptography and complex problem-solving.</p>
        </div>
        {/* benefits content with image */}
        <div className='software_benefits'>
            <img className='img2' src={softwareworking} alt="software_working" />
            <h5>Benefits of Software:</h5>
            <div className='benefits'>
                <p>Streamlines business processes, leading to faster completion times and reduced operational costs.</p>
                <p>Provides flexibility to adapt to changing requirements, ensuring long-term usability.</p>
                <p>Enhances the accuracy of data processing and reporting, improving decision-making.</p>
                <p>Easily scales to accommodate growth, whether it's handling more users, data, or transactions.</p>
                <p>Delivers user-friendly applications that improve customer satisfaction and engagement.</p>
                <p>Lowers maintenance costs by utilizing efficient coding practices and modern technology stacks.</p>
                <p>Provides tools for visualizing and interpreting data to inform strategy and operations.</p>
                <p>Enables rapid prototyping and experimentation, fostering a culture of innovation.</p>
                <p>Offers unique features and capabilities that differentiate an organization from its competitors.</p>
            </div>
        </div>
        {/* usage of software development with image */}
        <div className='software_usage'>
            <h5>Usage of Software:</h5>
            <div className='usage'>
                <p><b>Enterprise Resource Planning (ERP):</b> Manages core business processes such as finance, HR, supply chain, and inventory.</p>
                <p><b>E-commerce Platforms:</b> Online stores for selling products and services, like Amazon or Shopify.</p>
                <p><b>E-Learning Platforms:</b> Software like Coursera, Udemy, and Khan Academy offer online courses and certifications.</p>
                <p><b>Natural Language Processing (NLP):</b> Tools for voice recognition, chatbots, and automated translation.</p>
                <p><b>SaaS Applications:</b> Subscription-based software delivered over the internet (e.g., Microsoft 365, Salesforce).</p>
                <p><b>Infrastructure Management:</b> Cloud platforms like AWS and Azure for hosting applications.</p>
            </div>
            <img className='img3' src={softwaredevelopment} alt="software_development" />
        </div>
        {/* applications of software development with image */}
        <div className='software_applications'>
            <h5>Applications of Software:</h5>
            <div className='applications'>
                <p><b>HR Management Systems:</b> Automate payroll, attendance, recruitment, and employee management.</p>
                <p><b>Renewable Energy Software:</b> Optimize the use of solar, wind, and other renewable energy sources.</p>
                <p><b>IoT Integration:</b> Connect sensors and devices for monitoring traffic, energy usage, and public safety.</p>
                <p><b>Inventory and Order Management Systems:</b> Optimize stock levels, reduce waste, and manage order fulfillment.</p>
            </div>
            <img className='img4' src={softwareemployees} alt="software_employees" />
        </div>
        {/* software development down image */}
        <div className='software_down_image'>
            <img className='img5' src={softwareservice} alt="software_services" />
        </div>    
    </div>
  )
}

export default SoftwareDevelopment
