import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Products.css';
import clinicaldata from '../../Assets/clinical_data.png';
import faultdetection from '../../Assets/fault_detection_card.png';
import agrisense from '../../Assets/agrisense.jpg';

const Products = () => {

    // Scroll to top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  // cards with titles, descriptions and images are defined here
  const cardsData = [
    {
      id: 1,
      title: "Clinical Data",
      description: "Clinical data refers to the information collected during the course of a clinical trial or healthcare process. It can be used for medical research, drug development, treatment plans, and improving patient care.",
      imgSrc: clinicaldata,  // Using imported local image
      link: "/clinical_data", // Link to another page
    },
    {
      id: 2,
      title: "Fault Detection",
      description: "Fault Detection refers to the process of identifying and diagnosing malfunctions, anomalies, or failures within a system or process. It plays a critical role in various industries, including manufacturing, power generation, telecommunications, aerospace, and software development.",
      imgSrc: faultdetection,  // Using imported local image
      link: "/fault_detection", // Link to another page
    },
    {
      id: 3,
      title: "AgriSens",
      description: "AgriSens is an innovative agricultural solution that leverages cutting-edge sensing technology to transform traditional farming practices. Designed for precision agriculture, AgriSens enables farmers to monitor soil conditions, crop health, and environmental factors in real-time, leading to more informed decision-making and sustainable farming operations.",
      imgSrc: agrisense,  // Using imported local image
      link: "/agri_sens", // Link to another page
    }
  ];

  return (

    // products content is defined here
    <div className="container-products">
      {cardsData.map(card => (
        <div className="card-products" key={card.id}>
          <div className="image-wrapper">
            <img alt={card.title} src={card.imgSrc} />
          </div>
          <div className="content-products">
            <h2>{card.title}</h2>
            <p>{card.description}</p>
            <Link to={card.link} className="learn-more">Learn More</Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;
