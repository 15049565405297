import React from 'react';
// import { Link } from 'react-router-dom';
import './Footer.css';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
// import { FaGithub } from "react-icons/fa";

const Footer = () => {
  return (
    // footer section 
    <div className='footer'>
        {/* footer links section */}
        <div className='sb__footer section__padding'>
            {/* footer links container */}
            <div className='sb__footer-links'>
                {/* footer links div */}
                {/* <div className='sb__footer-links_div'>
                    <h4>For Business</h4>
                    <a href="/employer">
                       <p>Employers</p>
                    </a>
                    <a href="/healthplan">
                       <p>Health Plan</p>
                    </a>
                    <a href="/individual">
                       <p>Individual</p>
                    </a>
                </div>
                <div className='sb__footer-links_div'>
                    <h4>Resources</h4>
                    <a href="/resources">
                       <p>Resource Center</p>
                    </a>
                    <a href="/resources">
                       <p>Resources1</p>
                    </a>
                    <a href="/resources">
                       <p>Resources2</p>
                    </a>
                </div> */}
                {/* <div className='sb__footer-links_div'>
                    <h4>Patners</h4>
                    <a href="/resources">
                       <p>Employee1</p>
                    </a>
                    <a href="/resources">
                       <p>Employee2</p>
                    </a>
                    <a href="/resources">
                       <p>Employee3</p>
                    </a>
                </div> */}
                <div className='sb__footer-links_div'>
                    <h4>Company</h4>
                    <a href="/about">
                       <p>About Us</p>
                    </a>
                    <a href="/contact">
                       <p>Contact Us</p>
                    </a>
                    <a href="/domain">
                       <p>Domain</p>
                    </a>
                </div>
                <div className='sb__footer-links_div'>
                    <h4>Solutions</h4>
                    <a href="/clinical_data">
                       <p>Clinical Data</p>
                    </a>
                    <a href="/fault_detection">
                       <p>Fault Detection</p>
                    </a>
                    <a href="/agri_sens">
                       <p>AgriSens</p>
                    </a>
                </div>
                <div className='sb__footer-links_div'>
                    <h4>Guidelines</h4>
                    <a href="/terms_conditions">
                       <p>Terms & Conditions</p>
                    </a>
                    <a href="/privacy_policy">
                       <p>Privacy</p>
                    </a>
                    {/* <a href="/resources">
                       <p>Employee3</p>
                    </a> */}
                </div>
                <div className='sb__footer-links_div'>
                    <h4>Follow Us:</h4>
                    <div className='socialmedia'>
                <p className='icon'>
                    <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">
                        <FaFacebookF />
                    </a>
                </p>
                <p className='icon'>
                    <a href="https://www.instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
                        <FaInstagramSquare />
                    </a>
                </p>
                <p className='icon'>
                    <a href="https://www.linkedin.com/company/embed-view-solutions-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                    </a>
                </p>
                {/* <p className='icon'>
                    <a href="https://github.com/embed-view" target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                    </a>
                </p> */}
            </div>
                </div>
            </div>
            {/* <hr /> */}
            {/* footer below */}
            <div className='sb__footer-below'>
                {/* Note: in website "@2024 Embed View. All rights reserved." text in Chat.jsx */}
                {/* footer below container */}
                {/* <div className='sb__footer-copyright'>
                    <p>
                        @{new Date().getFullYear()} Embed View. All rights reserved.
                    </p>
                </div> */}
                {/* footer below links */}
                {/* <div className='sb__footer-below-links'>
                    <a href="/terms_conditions"><div><p>Terms & Conditions</p></div></a>
                    <a href="/privacy_policy"><div><p>Privacy</p></div></a>
                    <a href="/security"><div><p>Security</p></div></a>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default Footer
