import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactForm.css';

const ContactForm = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const [isLoading, setIsLoading] = useState(false); // New loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Capitalize the first letter for firstName and lastName
    const capitalizedValue = (name === 'firstName' || name === 'lastName') 
      ? value.charAt(0).toUpperCase() + value.slice(1) 
      : value;
  
    setFormData({
      ...formData,
      [name]: capitalizedValue,
    });
  };
  
  const validateEmail = (email) => {
    const allowedDomains = ['gmail.com', 'yahoo.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      return false;
    }

    const domain = email.split('@')[1];
    return allowedDomains.includes(domain);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email address as @gmail.com or @yahoo.com';
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required';
    } else if (!validatePhone(formData.phone)) {
      errors.phone = 'Must be an Indian number (10 digits starting with 6, 7, 8, or 9).';
    }

    if (!formData.message.trim()) {
      errors.message = 'Message cannot be empty';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true); // Set loading to true

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/contact/', {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });
      if (response.status === 200) {
        setResponseMessage('Message sent successfully!');
        setMessageType('success');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
        });
        setFormErrors({}); 
      }
    } catch (error) {
      console.error('There was an error sending the message!', error);
      setResponseMessage('Failed to send message.');
      setMessageType('error');
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  // Set a timeout to clear the responseMessage after 3 seconds (3000 milliseconds)
  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        setResponseMessage('');
        setMessageType(''); // Clear message type after the timeout
      }, 3000); // 3000 milliseconds = 3 seconds

      return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [responseMessage]);

  

  return (
    <div className="contact-form">
      {/* Office Info with image */}
      <div className="first-container">
        <div className="info-container">
          <div>
            <h3>Address</h3>
            <p>16-11-411/1, Moosarambagh Hyderabad, India 500036</p>
          </div>
          <div>
            <h3>Let's Talk</h3>
            <p>+91-9030620999</p>
          </div>
          <div>
            <h3>General Support</h3>
            <p>info@embed-view.com</p>
          </div>
        </div>
      </div>

      {/* Contact form with input fields */}
      <div className="second-container">
        <h2>Get In Touch</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name-input">Tell us your name*</label>
            <input
              id="name-input"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
            />
            {formErrors.firstName && (
              <span className="error" style={{ color: 'red' }}>{formErrors.firstName}</span>
            )}
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
            {formErrors.lastName && (
              <span className="error" style={{ color: 'red' }}>{formErrors.lastName}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email-input">Enter your email*</label>
            <input
              id="email-input"
              type="email"
              name="email"
              placeholder="Eg. example@gmail.com"
              value={formData.email}
              onChange={handleChange}
            />
            {formErrors.email && (
              <span className="error" style={{ color: 'red' }}>{formErrors.email}</span>
            )}
          </div>

          <div className="form-group">
          <label htmlFor="phone-input">Enter phone number*</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                backgroundColor: '#f0f0f0',
                padding: '20px',
                border: '1px solid #ccc',
                borderRight: 'none',
                borderRadius: '5px 0 0 5px',
                color: '#555',
              }}
            >
              +91
            </span>
            <input
              id="phone-input"
              type="tel"
              name="phone"
              placeholder="1234567890"
              value={formData.phone}
              onChange={handleChange}
              style={{
                paddingLeft: '10px',
                border: '1px solid #ccc',
                borderRadius: '0 5px 5px 0',
              }}
            />
          </div>
          {formErrors.phone && (
            <span className="error" style={{ color: 'red' }}>
              {formErrors.phone}
            </span>
          )}
        </div>


          <div className="form-group">
            <label htmlFor="message-textarea">Message</label>
            <textarea
              id="message-textarea"
              name="message"
              placeholder="Write us a message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {formErrors.message && (
              <span className="error" style={{ color: 'red' }}>{formErrors.message}</span>
            )}
          </div>

          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Please wait...' : 'Send message'}
          </button>
        </form>

        {responseMessage && (
          <div className={`response-message ${messageType}`} style={{ color: messageType === 'success' ? 'green' : 'red' }}>
            {responseMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
