import React, { useEffect } from 'react';
import './FaultDetection.css';
import faultdetection from '../../Assets/fault_detection.png';

const FaultDetection = () => {

      // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // fault detection content
    <div className='faultdetection slide-right'>
        <h1>Pipeline Voltage Monitoring with GSM and Cloud</h1>
        <img src={faultdetection} alt="faultdetection" />
        <p>
        Traditional pipeline monitoring methods are often inefficient and prone to errors. Modern technology offers a better solution: using GSM networks to 
        measure pipeline voltage, temperature, and humidity inside the measuring device and transmit data to the cloud.
        </p>
        {/* sub-faultdetection */}
        <div className='sub-faultdetection fade-in'>
            <h1>Why Temperature and Humidity Matter</h1>
            <p><b>Temperature:</b> High temperatures can cause components to overheat, increasing resistance and potential voltage drops. Overheating can also 
                accelerate the wear and tear of electrical components, potentially leading to failures.</p>
            <p>
            <b>Humidity:</b> Excess moisture in the air can lead to condensation on electrical components, increasing the risk of short circuits and corrosion. High 
            humidity levels can affect insulation resistance, leading to leakage currents and voltage instability.
            </p>
        </div>
        {/* sub-faultdetection2 */}
        <div className='sub-faultdetection2 slide-left'>
            <p>
            If the system detects conditions that could affect voltage stability, it triggers alerts to notify maintenance teams. This enables quick intervention 
            to mitigate risks, ensuring the safety and efficiency of the electrical system.
            </p>
            <p>
            GSM networks provide a reliable and widespread infrastructure for data transmission. Specialized devices with GSM modules can be installed along 
            pipelines to measure voltage. These devices send the data to a central server or cloud platform.
            </p>
            <p>
            Sensors are placed strategically on the pipeline to measure voltage accurately. They convert analog signals into digital data, then transmitted via GSM.
            </p>
            <p>
            Cloud-based data analysis enables real-time monitoring of pipeline health. Operators can identify potential issues, detect trends, and proactively prevent problems.
            </p>
        </div>
        {/* sub-faultdetection3 */}
        <div className='sub-faultdetection3 fade-in'>
            <p>Using GSM and cloud technology offers several advantages:</p>
            <div className='sub-faultdetection-para slide-left'>
                <p>Real-time monitoring.</p>
                <p>Reduced manual inspections.</p>
                <p>Optimised maintenance.</p>
                <p>Remote management.</p>
            </div>
            <p>This innovative approach enhances pipeline safety and efficiency.</p>
        </div>
    </div>
  )
}

export default FaultDetection
