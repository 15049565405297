import React, { useState, useEffect } from 'react';
import './FrontOfficerReceptionist.css';

const FrontOfficerReceptionist = () => {

    // Scroll to top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const [responseMessage, setResponseMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [messageColor, setMessageColor] = useState('green'); // State for message color
  const [phone, setPhone] = useState(''); // State for phone number
  const [isLoading, setIsLoading] = useState(false); // New loading state


    // Auto-hide response message after a few seconds
    useEffect(() => {
      if (responseMessage) {
        const timer = setTimeout(() => {
          setResponseMessage('');
        }, 3000); // Hide message after 3000 milliseconds (3 seconds)
  
        return () => clearTimeout(timer); // Clean up the timer
      }
    }, [responseMessage]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading state

    const form = event.target;
    const formData = new FormData(form);
    
      // Extract form data
      let fullName = formData.get('full_name');
      const email = formData.get('email');
      // const phone = formData.get('phone');
      const coverLetter = formData.get('cover_letter');
      // const resume = formData.get('resume');

      // Clear previous error messages
      setResponseMessage('');
      setFormErrors({});

          // Validate the fields
        const errors = {};
    
        if (!validateFullName(fullName)) {
          errors.fullName = 'Full name must start with a capital letter and contain only letters and spaces.';
        }
    
        if (!validateEmail(email)) {
          errors.email = 'Invalid email format."@gmail.com" or "@yahoo.com".';
        }
    
        if (!validatePhone(phone)) {
          errors.phone = 'Must be an Indian number (10 digits starting with 6, 7, 8, or 9).';
        }
    
        if (!validateCoverLetter(coverLetter)) {
          errors.coverLetter = 'Cover letter must be at least 20 characters long.';
        }

            // If there are any errors, stop form submission and display errors
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // setResponseMessage('Please fix the errors above.'); // Optional message for validation errors
      setMessageColor('red'); // Set color to red for errors
      setIsLoading(false); // Reset loading state
      return;
    }


    try {
      const response = await fetch('http://127.0.0.1:8000/api/front_officer_receptionist/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'The application has been successfully submitted.');
        setMessageColor('green'); // Set color to green for success
        // Reset the phone number after successful submission
        setPhone(''); // Clear the phone input
        form.reset(); // Reset the form fields after successful submission
      } else {
        const errorData = await response.json();
            // Check for specific error message regarding existing email
            if (response.status === 409) { // Assuming 409 for email conflict
              setResponseMessage('Error: Email already exists.');
          } 
          else {
              setResponseMessage('Error: ' + JSON.stringify(errorData));
          }
          setMessageColor('red');
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
      setMessageColor('red'); // Set color to red for catch errors
    }finally {
      setIsLoading(false); // Always reset loading state
    }
  };

  const validateFullName = (name) => {
    // Check if the full name starts with a capital letter and only contains letters and spaces
    const fullNameRegex = /^[A-Z][a-z]*(?:\s[A-Z][a-z]*)*$/;
    return fullNameRegex.test(name.trim());
  };
  const validateEmail = (email) => {
    const allowedDomains = ['gmail.com', 'yahoo.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailRegex.test(email)) {
      return false; // Invalid email format
    }
  
    // Extract the domain from the email and check if it's allowed
    const emailDomain = email.split('@')[1];
    return allowedDomains.includes(emailDomain);
  };
  

  const validatePhone = (phone) => {
    // Check if the phone number is a valid Indian phone number (10 digits)
    const phoneRegex = /^(?:[6789]\d{9})$/; // Starts with 7, 8, or 9 and followed by 9 digits
    return phoneRegex.test(phone);
  };

  const validateCoverLetter = (coverLetter) => {
    return coverLetter.trim().length >= 20;
  };

  
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only digits
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  return (
    // front officer receptionist page
    <div className="receptionist-description-container">
        {/* front officer receptionist description */}
        <div className="receptionist-details">
            <h2>Front Officer Receptionist</h2>
            <h3>Responsibilities</h3>
            {/* front officer receptionist responsibilities description */}
            <div className='receptionist-description'>
                <p><b>Greeting and Assisting Visitors:</b> Warmly welcome guests, provide them with necessary information, and direct them to their destinations or appropriate staff members.</p>
                <p><b>Managing Phone Calls:</b> Handle incoming calls, take messages, and route calls to the relevant departments or individuals.</p>
                <p><b>Scheduling Appointments:</b> Coordinate and manage appointment calendars for staff members, and book meeting rooms as needed.</p>
                <p><b>Handling Administrative Tasks:</b> Perform clerical duties such as filing, data entry, and handling mail to support office operations.</p>
                <p><b>Maintaining Reception Area:</b> Ensure the reception area is tidy, organized, and equipped with necessary supplies, creating a professional and welcoming environment.</p>
            </div>
            <h3>Required Education Skills</h3>
            {/* front officer receptionist education skills description */}
            <div className='receptionist-description'>
                <p><b>Educational Background: </b>High school diploma or equivalent; additional certifications in office management or customer service are advantageous.</p>
                <p><b>Communication Skills:</b> Excellent verbal and written communication skills to interact effectively with clients and team members.</p>
                <p><b>Technical Proficiency:</b> Basic knowledge of office software (e.g., Microsoft Office) and familiarity with common office equipment (e.g., printers, scanners).</p>
                <p><b>Organizational Skills:</b> Strong organizational abilities to manage multiple tasks and maintain a well-ordered front office.</p>
                <p><b>Customer Service: </b>Exceptional customer service skills to handle inquiries, resolve issues, and provide a positive experience for visitors.</p>
            </div>
            {/* front officer receptionist additional description */}
            <div className='receptionist-description-info'>
                <p><b>Job Category:</b>Front Officer Receptionist</p>
                <p><b>Job Type:</b>Full Time</p>
                <p><b>Job Location:</b>Moosarambagh</p>
            </div>
        </div>
        {/* front officer receptionist apply form */}
        <div className="receptionist-apply-form">
            <h3>Apply for this position</h3>
            {/*front officer receptionist apply form box */}
            <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name"/>
          {formErrors.fullName && <p className="error-message">{formErrors.fullName}</p>}
          
          <label>Email *</label>
          <input type="email" name="email"/>
          {formErrors.email && <p className="error-message">{formErrors.email}</p>}
          
          <label>Phone *</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px', color: '#000' }}>+91</span>
            <input
              type="tel"
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              maxLength={10}
              placeholder="Enter phone number"
              style={{ flex: 1,  border: '1px solid #ccc', borderRadius: '4px',marginTop: '15px' }} // Make input take remaining space
            />
          </div>
          {formErrors.phone && <p className="error-message">{formErrors.phone}</p>}
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4"></textarea>
          {formErrors.coverLetter && <p className="error-message">{formErrors.coverLetter}</p>}

          <label htmlFor="resume">Resume *</label>
          <input type="file" id="resume" name="resume"/>
          {formErrors.resume && <p className="error-message">{formErrors.resume}</p>}
          
          <button type="submit" disabled={isLoading}>Submit</button>
        </form>

         {/* Response message */}
         {isLoading ? (
          <div className="loading-message">
            <p>Please wait...</p>
          </div>
        ) : (
          responseMessage && (
            <div className="response-message" style={{ color: messageColor }}>
              <p>{responseMessage}</p>
            </div>
          )
        )}
        </div>
    </div>
  )
}

export default FrontOfficerReceptionist
