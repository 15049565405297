import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileForGoogleLogin.css';

const ProfileForGoogleLogin = () => {

      // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear user information from localStorage
        localStorage.removeItem('email');
        localStorage.removeItem('username');

        // Redirect to the login page
        navigate('/account');
    };

    // Retrieve user information from localStorage
    const email = localStorage.getItem('email');
    const username = localStorage.getItem('username'); // Retrieve username from localStorage

    return (
        <div className="profile">
            <h1 className='title'>Profile </h1>
            <h1 className='subtitle'><strong>Username:</strong> {username || 'Username not found'}</h1>
            <h1 className='subtitle'><strong>Email:</strong> {email || 'Email not found'}</h1>
            <button className="logout-button" onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default ProfileForGoogleLogin;
